import {
  Box,
  Button,
  Container,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axapi from "../../utils/axios";

export const Waitlist = () => {
  const [userList, setUserList] = useState([]);
  const [open, setOpen] = useState(false);
  const [idCurrent, setIdCurrent] = useState("");
  const [nameCurrent, setNameCurrent] = useState("");
  const [emailCurrnent, setEmailCurrnent] = useState("");
  const [checkedUsers, setCheckedUsers] = useState(
    userList.reduce((acc, user) => ({ ...acc, [user._id]: false }), {})
  );
  const [options, setOptions] = useState("");
  useEffect(() => {
    axapi
      .get("/admin/waitlist")
      .then((res) => {
        setUserList(res.data);
      })
      .catch((err) => {
        toast.error("Some error occured, please check console");
        console.log(err);
      });
  }, []);

  const rejectWaitlistEntry = (id, name, email) => {
    setIdCurrent(id);
    setNameCurrent(name);
    setEmailCurrnent(email);
    setOpen(true);
  };

  const cancelRejection = () => {
    setIdCurrent("");
    setNameCurrent("");
    setEmailCurrnent("");
    setOpen(false);
  };

  const handleRejection = async () => {
    const res = await axapi
      .post("/admin/waitlist/reject", { _id: idCurrent })
      .catch((e) => {
        toast.error("Some Error Occured");
        console.log(e);
      });
    toast.success(res.data.message);
    setUserList(userList.filter((user) => user._id !== idCurrent));
    cancelRejection();
  };

  const handleApproval = async (_id, role) => {
    try {
      const res = await axapi.post("/admin/waitlist/approve", {
        _id,
        role,
        // options,
      });
      // setCheckedUsers({...checkedUsers, [_id]: false});// Reset checked state
      setUserList(userList.filter((user) => user._id !== _id));
      toast.success(res.data.message);
      cancelRejection();
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log("500");
        const errorMessage = error.response.data.error;
        console.log(errorMessage);
        // Check if it's a 550 SMTP server error
        if (errorMessage.includes("SMTP server error: Unable to send email")) {
          toast.error(
            "SMTP server error: Unable to send email. Please try again later."
          );
        } else {
          toast.error("Some Error Occurred");
        }
      } else {
        console.error(error);
        toast.error("Some Error Occurred");
      }
    }
  };
  // const handleCheckboxChange = (event, userId) => {
  //   setCheckedUsers({
  //     ...checkedUsers,
  //     [userId]: event.target.checked,
  //   });
  //   setOptions(event.target.checked ? 'short' : ''); // Set options based on checked state
  // };
  return (
    <>
      <br />
      <Container>
        <Typography variant="h3">Waitlist</Typography>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                {/* <TableCell>New Invite Template</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {userList.map((user) => (
                <TableRow key={user.name + Math.random() * 10}>
                  <TableCell component="th" scope="row">
                    {user.name}
                  </TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>
                    <InputLabel id="demo-simple-select-label">
                      Approve As ROLE
                    </InputLabel>
                    <Select
                      label="Approve as Role"
                      value={"DEFAULT"}
                      onChange={(e) => handleApproval(user._id, e.target.value)}
                    >
                      <MenuItem value={"DEFAULT"}>Select a ROLE</MenuItem>
                      <MenuItem value={"USER"}>USER</MenuItem>
                      <MenuItem value={"PROFESSOR"}>Professor</MenuItem>
                    </Select>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => {
                        rejectWaitlistEntry(user._id, user.name, user.email);
                      }}
                    >
                      Reject
                    </Button>
                  </TableCell>
                  {/* <TableCell>
                      <Checkbox
                        variant="contained"
                        color="primary"
                        checked={checkedUsers[user._id]} // Use checked state from checkedUsers
                        onChange={(event) => handleCheckboxChange(event, user._id)}
                      />
                    </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
      <Modal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={open}
        onClose={() => setOpen(false)}
        slots={{}}
      >
        <Box
          sx={{
            display: "flex",
            height: "100vh",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Paper sx={{ padding: "32px", width: "auto" }}>
            <Typography variant="h6">Confirm Delete ?</Typography>
            <br />
            <div>Name: {nameCurrent}</div>
            <div>Email: {emailCurrnent}</div>
            <div>_id: {idCurrent}</div>
            <br />
            <div className="flex justify-between">
              <Button
                variant="outlined"
                color="success"
                onClick={cancelRejection}
              >
                No
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={handleRejection}
              >
                Yes
              </Button>
            </div>
          </Paper>
        </Box>
      </Modal>
    </>
  );
};
