import React from "react";
import { Route } from "react-router-dom";
import AuthGuard from "../utils/guards/auth-guard";
import HomePage from "../components/app/HomePage";
import { Waitlist } from "../components/app/Waitlist";
import { PendingUsers } from "../components/app/PendingUsers";
import { ActivatedUsers } from "../components/app/ActivatedUsers";
import { AddFeatures } from "../components/app/AddFeatures";
import Benchmark from "../components/app/Benchmark";
import { CreditsSetting } from "../components/app/Credits";
import  {GenerateLink}  from "../components/app/GenerateLink";
const AuthRoutes = [
  <Route
    key="/"
    path="/"
    exact
    element={<AuthGuard component={<HomePage />} />}
  />,
  <Route
    key="/waitlist"
    path="/waitlist"
    exact
    element={<AuthGuard component={<Waitlist />} />}
  />,
  <Route
    key="/pendinguserslist"
    path="/pendinguserslist"
    exact
    element={<AuthGuard component={<PendingUsers />} />}
  />,
  <Route
    key="/userslist"
    path="/userslist"
    exact
    element={<AuthGuard component={<ActivatedUsers />} />}
  />,

  <Route
    key="/addfeatures"
    path="/addfeatures"
    exact
    element={<AuthGuard component={<AddFeatures />} />}
  />,
  <Route
    key="/benchmark"
    path="/benchmark"
    exact
    element={<AuthGuard component={<Benchmark />} />}
  />,
  <Route
    key="/credits-setting"
    path="/credits-setting"
    exact
    element={<AuthGuard component={<CreditsSetting />} />}
  />,
  <Route
    key="/generate-link"
    path="/generate-link"
    exact
    element={<AuthGuard component={<GenerateLink />} />}
  />,
];

export default AuthRoutes;
