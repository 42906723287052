import { atom, selector } from 'recoil';

export const authState = atom({
  key: 'auth',
  default: {
    auth: false,
    token: '',
    user: '',
  },
});

export const authSelector = selector({
  key: 'authSelector',
  get: ({ get }) => {
    const isAuth = get(authState).auth;
    return !!isAuth;
  },
});
