// @ts-check
import React, { useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import axapi from "../../utils/axios";
import { Box, Button, Divider, FormControl, FormControlLabel, Switch, TextareaAutosize } from "@mui/material";
import { useSnackbar } from "notistack";

/**
 * @typedef {Object} PromptConfig
 * @property {string} prompt
 * @property {string} formatInstructions
 * @property {boolean} pointByPoint
 */

export const BenchmarkPrompt = () => {
  const snackbar = useSnackbar();

  const defaultPromptQuery = useQuery({
    queryKey: ["defaultPrompt"],
    queryFn: async () => {
      const res = await axapi.get("/benchmark/prompt", { params: { default: true } });
      /**@type {PromptConfig} */
      const data = res.data;
      return data;
    },
  });

  const promptQuery = useQuery({
    queryKey: ["prompt"],
    queryFn: async () => {
      const res = await axapi.get("/benchmark/prompt");
      /**@type {PromptConfig} */
      const data = res.data;
      return data;
    },
  });

  const promptMutation = useMutation({
    mutationKey: ["prompt"],
    /** @param {Partial<PromptConfig>} prompt */
    mutationFn: async prompt => {
      if (!prompt.prompt || !prompt.formatInstructions) throw new Error("Prompt and format instructions are required");
      await axapi.post("/benchmark/prompt", prompt);
    },
    onSuccess: () => {
      promptQuery.refetch();
      snackbar.enqueueSnackbar("Saved", { variant: "success" });
    },
    onError: e => {
      snackbar.enqueueSnackbar(e.message ?? "Error", { variant: "error" });
    },
  });

  const [evaluateResponse, setEvaluateResponse] = useState("");

  const evaluateMutation = useMutation({
    mutationKey: ["evaluate"],
    /** @param {{ prompt?: string, benchmarkAnswer?: string, testAnswer?: string, formatInstructions?: string, }} data */
    mutationFn: async data => {
      if (!data.prompt || !data.benchmarkAnswer || !data.testAnswer)
        throw new Error("Benchmark Answer and Test Answer are required");
      const response = await axapi.post("/benchmark/evaluate", data);
      Object.entries(response.data).forEach(([k, v]) => {
        console.log(k, "\t", v);
      });
      if (typeof response.data.result === "string") setEvaluateResponse(response.data.result);
      else setEvaluateResponse(JSON.stringify(response.data.result, null, 4));
      return response;
    },
    onSuccess: () => {
      snackbar.enqueueSnackbar("Check browser console for response", { variant: "success" });
    },
    onError: e => {
      snackbar.enqueueSnackbar(e.message ?? "Error", { variant: "error" });
    },
  });

  const isPending =
    defaultPromptQuery.isPending || promptQuery.isPending || promptMutation.isPending || evaluateMutation.isPending;

  /**@type {[Partial<PromptConfig>, any]} */
  const [form, setForm] = useState({
    prompt: undefined,
    formatInstructions: undefined,
    pointByPoint: undefined,
  });

  const [benchmarkAnswer, setBenchmarkAnswer] = useState("");
  const [testAnswer, setTestAnswer] = useState("");

  const hasNoFormatInstructions = !(form.prompt ?? promptQuery.data?.prompt)?.includes("{formatInstructions}");

  return (
    <Box className="w-full">
      <FormControl sx={{ m: 2 }} disabled={isPending} className="!flex !flex-col">
        <Box>
          <Box>Prompt</Box>
          <TextareaAutosize
            disabled={isPending}
            className="m-2 w-full text-sm font-normal font-sans leading-normal p-3 rounded-xl rounded-br-none shadow-lg shadow-slate-100 focus:shadow-lg border border-solid border-slate-300 hover:border-purple-500 focus:border-purple-500 bg-white text-slate-900 focus-visible:outline-0 box-border"
            value={form.prompt ?? promptQuery.data?.prompt}
            onChange={e => {
              e.preventDefault();
              setForm(f => ({ ...f, prompt: e.target.value }));
            }}
          />
        </Box>

        <Box>
          <Box>
            Format Instructions {hasNoFormatInstructions && "(prompt must have '{formatInstructions}' to use this)"}
          </Box>
          {!hasNoFormatInstructions && (
            <TextareaAutosize
              className="m-2 w-full text-sm font-normal font-sans leading-normal p-3 rounded-xl rounded-br-none shadow-lg shadow-slate-100 focus:shadow-outline-purple focus:shadow-lg border border-solid border-slate-300 hover:border-purple-500 focus:border-purple-500 bg-white text-slate-900 focus-visible:outline-0 box-border"
              disabled={isPending || hasNoFormatInstructions}
              value={form.formatInstructions ?? promptQuery.data?.formatInstructions}
              onChange={e => {
                e.preventDefault();
                setForm(f => ({ ...f, formatInstructions: e.target.value }));
              }}
            />
          )}
        </Box>
        <FormControlLabel
          sx={{ m: 2, display: "none" }}
          control={
            <Switch
              disabled={true || isPending}
              checked={form.pointByPoint ?? promptQuery.data?.pointByPoint}
              onChange={e => {
                e.preventDefault();
                setForm(f => ({ ...f, pointByPoint: e.target.checked }));
              }}
            />
          }
          label="Point by point"
        />
      </FormControl>
      <Box>
        <Button
          variant="contained"
          onClick={() =>
            promptMutation.mutate({
              formatInstructions: form.formatInstructions ?? promptQuery.data?.formatInstructions,
              prompt: form.prompt ?? promptQuery.data?.prompt,
              pointByPoint: form.pointByPoint ?? promptQuery.data?.pointByPoint,
            })
          }
          disabled={isPending}>
          Save
        </Button>
        <Button variant="outlined" onClick={() => setForm(defaultPromptQuery.data)} disabled={isPending}>
          Reset
        </Button>
      </Box>

      <Divider sx={{ py: 2 }} />

      <FormControl sx={{ m: 2 }} disabled={isPending} className="!flex !flex-col">
        <Box>
          <Box>Benchmark answer</Box>
          <TextareaAutosize
            className="m-2 w-full text-sm font-normal font-sans leading-normal p-3 rounded-xl rounded-br-none shadow-lg shadow-slate-100 focus:shadow-outline-purple focus:shadow-lg border border-solid border-slate-300 hover:border-purple-500 focus:border-purple-500 bg-white text-slate-900 focus-visible:outline-0 box-border"
            disabled={isPending}
            value={benchmarkAnswer}
            onChange={e => {
              e.preventDefault();
              setBenchmarkAnswer(e.target.value);
            }}
          />
        </Box>

        <Box>
          <Box>Test answer</Box>
          <TextareaAutosize
            className="m-2 w-full text-sm font-normal font-sans leading-normal p-3 rounded-xl rounded-br-none shadow-lg shadow-slate-100 focus:shadow-outline-purple focus:shadow-lg border border-solid border-slate-300 hover:border-purple-500 focus:border-purple-500 bg-white text-slate-900 focus-visible:outline-0 box-border"
            disabled={isPending}
            value={testAnswer}
            onChange={e => {
              e.preventDefault();
              setTestAnswer(e.target.value);
            }}
          />
        </Box>

        {evaluateResponse && (
          <Box>
            <Box>Response</Box>
            <Box className="whitespace-pre-line font-mono">{evaluateResponse}</Box>
          </Box>
        )}

        <Box>
          <Button
            variant="contained"
            disabled={isPending}
            onClick={() =>
              evaluateMutation.mutate({
                benchmarkAnswer,
                testAnswer,
                prompt: form.prompt ?? promptQuery.data?.prompt,
                formatInstructions: form.formatInstructions ?? promptQuery.data?.formatInstructions,
              })
            }>
            Evaluate
          </Button>
        </Box>
      </FormControl>
    </Box>
  );
};
