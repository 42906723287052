import { useState } from "react";
import { Button } from "../../ui/button.tsx";
import { Input } from "../../ui/input.tsx";
import { Label } from "../../ui/label.tsx";
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
  CardFooter,
} from "../../ui/card.tsx";
import { z } from "zod";

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "../../ui/dialog.tsx";

import axapi from "../../utils/axios";
import { toast } from "react-toastify";

const schema = z.object({
  name: z.string().min(2, { message: "Name must be at least 2 characters." }),
  email: z.string().email({ message: "Please enter a valid email address." }),
  position: z.string({ message: "Position is required." }).optional(),
  profilePictureUrl: z.string().optional(),
});

export const GenerateLink = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    position: "",
    profilePictureUrl: "",
  });

  const [errors, setErrors] = useState({});
  const [generatedLink, setGeneratedLink] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [copyButtonLabel, setCopyButtonLabel] = useState("Copy Link");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateForm = () => {
    const result = schema.safeParse(formData);

    if (!result.success) {
      const fieldErrors = result.error.format();
      setErrors({
        name: fieldErrors.name?._errors[0] || "",
        email: fieldErrors.email?._errors[0] || "",
        position: fieldErrors.position?._errors[0] || "",
        profilePictureUrl: fieldErrors.profilePictureUrl?._errors[0] || "",
      });
      return false;
    }
    setErrors({});
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const generateLink = async () => {
        try {
          const response = await axapi.post(
            "/admin/waitlist/generate-link",
            formData
          );
          console.log("Response:", response.data);
          setGeneratedLink(response.data.activationLink);
          setIsModalOpen(true);

          setFormData({
            name: "",
            email: "",
            position: "",
            profilePictureUrl: "",
          });
        } catch (error) {
          console.error("Error:", error);
          toast.error("Error generating link." + error.message);
          setFormData({
            name: "",
            email: "",
            position: "",
            profilePictureUrl: "",
          });
        }
      };

      generateLink();
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(generatedLink);
    setCopyButtonLabel("Copied");
    setTimeout(() => setCopyButtonLabel("Copy Link"), 2000); 
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <Card className="w-full max-w-md mx-auto">
        <CardHeader>
          <CardTitle>Profile Information</CardTitle>
          <CardDescription>Please enter user details below</CardDescription>
        </CardHeader>
        <CardContent>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="name">Name</Label>
              <Input
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
              {errors.name && (
                <p className="text-red-500 text-sm">{errors.name}</p>
              )}
            </div>
            <div className="space-y-2">
              <Label htmlFor="email">Email</Label>
              <Input
                id="email"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
              {errors.email && (
                <p className="text-red-500 text-sm">{errors.email}</p>
              )}
            </div>
            <div className="space-y-2">
              <Label htmlFor="position">Position</Label>
              <Input
                id="position"
                name="position"
                value={formData.position}
                onChange={handleChange}
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="profilePictureUrl">Profile Picture URL</Label>
              <Input
                id="profilePictureUrl"
                name="profilePictureUrl"
                type="url"
                value={formData.profilePictureUrl}
                onChange={handleChange}
                placeholder="https://example.com/profile-picture.jpg"
              />
            </div>
          </form>
        </CardContent>
        <CardFooter>
          <Button type="submit" className="w-full" onClick={handleSubmit}>
            Submit
          </Button>
        </CardFooter>
      </Card>

      <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Generated Link</DialogTitle>
            <DialogDescription>Copy the generated link below</DialogDescription>
          </DialogHeader>
          <Input
            id="generatedLink"
            name="generatedLink"
            type="url"
            value={generatedLink}
            readOnly
            className="mb-4"
          />
          <DialogFooter>
            <Button onClick={handleCopy} className="mr-2">
              {copyButtonLabel}
            </Button>
            <Button onClick={() => setIsModalOpen(false)}>Close</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};
