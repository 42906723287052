import React from 'react';
import { Route } from 'react-router-dom';
import AnonymousGuard from '../utils/guards/anonymous-guard';
import { Login } from './../components/auth/login';

const UnAuthRoutes = [
  <Route
    key="/auth/login"
    path="/auth/login"
    element={<AnonymousGuard component={<Login />} />}
  />,
];

export default UnAuthRoutes;
