import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { RecoilRoot } from 'recoil';
import App from './App';
import { GoogleOAuthProvider } from '@react-oauth/google';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>

  <GoogleOAuthProvider clientId="749128843735-797vdcbpobbusahk28i7h4avf89q20gf.apps.googleusercontent.com">
    <RecoilRoot>
      <App />
    </RecoilRoot>
  </GoogleOAuthProvider>
  // </React.StrictMode>
);
