import React from 'react';
import { useRecoilValue } from 'recoil';
import { authState } from '../../state/auth';
import { Navigate } from 'react-router-dom';

const AuthGuard = ({ component }) => {
  const { auth } = useRecoilValue(authState);

  return auth ? <>{component}</> : <Navigate to={'/auth/login'} />;
};

export default AuthGuard;
