// @ts-check
import * as React from "react";
import { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  Button,
  Box,
  MenuItem,
  InputLabel,
  FormControl,
  FormControlLabel,
  Switch,
  ButtonGroup,
  Dialog,
  DialogContent,
} from "@mui/material";
import axapi from "../../utils/axios";
import { useQuery } from "@tanstack/react-query";
import { BenchmarkPrompt } from "../misc/BenchmarkPrompt";

/**
 * @typedef {Object} Question
 * @property {string} query - The query of the question.
 * @property {string[]} shortAnswer - The short answer to the question.
 * @property {string[]} longAnswer - The long answer to the question.
 */

/**
 * @typedef {Object} Benchmark
 * @property {string} _id - The unique identifier of the document.
 * @property {string} pdfFile - The unique identifier of the PDF file associated with the document.
 * @property {number} score - The score of the document.
 * @property {string[]} missingData - An array of missing data in the document.
 * @property {string[]} [partiallyMissingData] - An array of partially missing data in the document.
 * @property {number} [levenshteinDistance] - Levenshtein distance between the answer and response
 * @property {string} questionId - The unique identifier of the question associated with the document.
 * @property {Question} question - The question object associated with the document.
 * @property {string} response - The response to the question in the document.
 * @property {boolean} isLong - Indicates if the response is long.
 * @property {Date} createdAt - The date and time when the document was created.
 * @property {Date} updatedAt - The date and time when the document was last updated.
 * @property {number} __v - The version of the document.
 */

/**
 * @typedef {Object} File
 * @property {string} _id
 * @property {string} originalName
 * @property {string} path
 * @property {string} user
 * @property {boolean} processed
 * @property {boolean} pinecone
 * @property {boolean} deleted
 * @property {string} fileHash
 * @property {string} lastOpenTime
 * @property {Date} createdAt
 * @property {Date} updatedAt
 * @property {number} __v
 */

/**
 * @typedef {Object} BenchmarkData
 * @property {string} name
 * @property {string} paperId
 * @property {Question[]} questions
 */

/**
 * @param {string} message
 * @returns {Promise<string>}
 */
async function sha256(message) {
  const msgBuffer = new TextEncoder().encode(message);
  const hashBuffer = await crypto.subtle.digest("SHA-256", msgBuffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, "0")).join("");
  return hashHex;
}

/**
 * @param {Question} question
 * @returns {Promise<string>}
 */
const questionToID = async question => {
  return await sha256(question.query + question.shortAnswer.join() + question.longAnswer.join());
};

/**
 * @param {string} str
 * @returns {string}
 */
const removeDiggingDeeper = str => {
  const index = str.indexOf("**Digging deeper**");
  if (index !== -1) {
    return str.substring(0, index);
  }
  return str;
};

export default function Benchmark() {
  const [promptConfigOpen, setPromptConfigOpen] = useState(false);
  const [filters, setFilters] = useState({
    pdfFile: "",
    questionId: "",
    shortAnswers: true,
    longAnswers: true,
    offset: 0,
    limit: 10,
  });

  const filesQuery = useQuery({
    queryKey: ["files"],
    queryFn: async () => {
      const res = await axapi.get("/benchmark/paperList");
      /**@type {File[]} */
      const data = res.data.paperList;
      return data;
    },
  });

  const questionsQuery = useQuery({
    queryKey: ["questions", filters.pdfFile],
    queryFn: async () => {
      const res = await axapi.get("/benchmark/questionList", {
        params: {
          paperId: filters.pdfFile || undefined,
        },
      });
      /**@type {[BenchmarkData]} */
      const _data = res.data.questions;
      const data = _data[0];
      if (!data) return [];
      const result = await Promise.all(
        data.questions.map(async question => ({
          ...question,
          _id: await questionToID(question),
        }))
      );
      console.info(result);
      return result;
    },
    enabled: !!filters.pdfFile,
  });

  const rowsQuery = useQuery({
    queryKey: ["benchmark", filters],
    queryFn: async () => {
      const res = await axapi.get("/benchmark/data", {
        params: {
          pdfFile: filters.pdfFile || undefined,
          questionId: filters.questionId || undefined,
          isLong: filters.shortAnswers && filters.longAnswers ? undefined : filters.longAnswers,
          offset: filters.offset || 0,
          limit: filters.limit || 10,
        },
      });
      /**@type {Benchmark[]} */
      const data = res.data.documents;
      return data;
    },
  });

  const isLoading = filesQuery.isLoading || questionsQuery.isLoading || rowsQuery.isLoading;

  return (
    <TableContainer component={Paper}>
      <Dialog fullWidth maxWidth={false} open={promptConfigOpen} onClose={() => setPromptConfigOpen(false)}>
        <DialogContent>{promptConfigOpen && <BenchmarkPrompt />}</DialogContent>
      </Dialog>
      <Box sx={{ minWidth: 120 }}>
        <FormControl sx={{ m: 2 }} disabled={isLoading} className="!flex !flex-row">
          <FormControl sx={{ m: 2 }} disabled={isLoading}>
            <InputLabel id="file-selector-label">File</InputLabel>
            <Select
              sx={{ minWidth: 256 }}
              labelId="file-selector-label"
              id="file-selector-select"
              value={filters.pdfFile}
              label="File"
              onChange={e => {
                setFilters(filters => ({ ...filters, pdfFile: e.target.value }));
              }}>
              <MenuItem value="">None</MenuItem>
              {filesQuery.data?.map(file => (
                <MenuItem value={file._id} key={file._id}>
                  {file.originalName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{ m: 2 }} disabled={isLoading}>
            <InputLabel id="question-selector-label">Question</InputLabel>
            <Select
              sx={{ minWidth: 256 }}
              labelId="question-selector-label"
              id="question-selector-select"
              value={filters.questionId}
              label="Question"
              onChange={e => {
                setFilters(filters => ({ ...filters, questionId: e.target.value }));
              }}>
              <MenuItem value="">None</MenuItem>
              {questionsQuery.data?.map(question => (
                <MenuItem value={question._id} key={question._id}>
                  {question.query}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControlLabel
            sx={{ m: 2 }}
            control={
              <Switch
                checked={filters.shortAnswers}
                onChange={e => {
                  setFilters(filters => ({ ...filters, shortAnswers: e.target.checked }));
                }}
              />
            }
            label="Short answers"
          />
          <FormControlLabel
            sx={{ m: 2 }}
            control={
              <Switch
                checked={filters.longAnswers}
                onChange={e => {
                  setFilters(filters => ({ ...filters, longAnswers: e.target.checked }));
                }}
              />
            }
            label="Long answers"
          />
          <Button variant="contained" onClick={() => setPromptConfigOpen(true)}>
            Prompt config
          </Button>
        </FormControl>
      </Box>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell>Question</TableCell>
            <TableCell>Score</TableCell>
            <TableCell>Response</TableCell>
            <TableCell>Missing data (highlighted in red)</TableCell>
            <TableCell>Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rowsQuery.data?.map(row => {
            const answer = row.isLong ? row.question.longAnswer : row.question.shortAnswer;
            return (
              <TableRow key={row._id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell component="th" scope="row">
                  <Box>
                    <Box>{filesQuery.data?.find(file => file._id === row.pdfFile)?.originalName}</Box>
                    <Box>{row.question.query}</Box>
                    <Box>{row.isLong ? "Long" : "Short"}</Box>
                    {row.levenshteinDistance && <Box>Levenshtein: {row.levenshteinDistance}</Box>}
                  </Box>
                </TableCell>
                <TableCell>{row.score}</TableCell>
                <TableCell>
                  <Box className="max-w-4xl max-h-64 overflow-auto">
                    <Box className="whitespace-pre-line">{removeDiggingDeeper(row.response)}</Box>
                  </Box>
                </TableCell>
                <TableCell className="max-w-xl">
                  {answer.map(p => (
                    <div
                      key={p}
                      className={
                        row.missingData.includes(p)
                          ? "bg-red-300"
                          : row.partiallyMissingData?.includes(p)
                          ? "bg-yellow-300"
                          : ""
                      }>
                      {p}
                    </div>
                  ))}
                </TableCell>
                <TableCell>{new Date(row.createdAt).toUTCString()}</TableCell>
              </TableRow>
            );
          })}
          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell colSpan={5}>
              <ButtonGroup disabled={isLoading}>
                <Button
                  onClick={() => {
                    setFilters(filters => ({ ...filters, offset: filters.offset - filters.limit }));
                  }}>
                  Previous
                </Button>
                <Button disabled>
                  {filters.offset + 1}-{filters.offset + filters.limit}
                </Button>
                <Button
                  onClick={() => {
                    setFilters(filters => ({ ...filters, offset: filters.offset + filters.limit }));
                  }}>
                  Next
                </Button>
              </ButtonGroup>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
