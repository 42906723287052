import {
  Container,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  CircularProgress,
  Tab,
} from "@mui/material";
import Credits from "../modals/Credits";

import { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import axapi from "../../utils/axios";

export const CreditsSetting = () => {
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openCreditsInfo, setOpenCreditsInfo] = useState(false);
  const userState = useRef({ id: "", name: "" });

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axapi.get("/admin/userlist");

        console.log(response.data);
        const usersList = response.data;
        usersList.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setUserList(response.data);
      } catch (err) {
        toast.error("Some error occurred, please check console");
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };
    fetchUsers();
  }, []);

  const handleSetCredits = async ({ username, userId }) => {
    userState.current.name = username;
    userState.current.id = userId;

    setOpenCreditsInfo(!openCreditsInfo);
  };
  if (isLoading) {
    return (
      <Container
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Container>
    );
  }
  if (isLoading) {
    return (
      <Container
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Container>
    );
  }

  return (
    <>
      
      <br />
      <Container>
        <Typography variant="h3">Credits of Activated Users</Typography>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Credits</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userList.map((user) => (
                <TableRow key={user.name + Math.random() * 10}>
                  <TableCell component="th" scope="row">
                    {user.name}
                  </TableCell>
                  <TableCell>{user.email}</TableCell>

                  <TableCell>
                    <Button
                      variant="contained"
                      color="success"
                      onClick={() =>
                        handleSetCredits({
                          username: user.name,
                          userId: user._id,
                        })
                      }
                      disabled={user.isSubscribed === false}
                    >
                      Credits
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
      {openCreditsInfo && (
        <Credits
          openCreditsInfo={openCreditsInfo}
          setOpenCreditsInfo={setOpenCreditsInfo}
          userState={userState.current}
        />
      )}
    </>
  );
};
