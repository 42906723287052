import React from 'react';
import { useRecoilValue } from 'recoil';
import { authState } from '../../state/auth';
// import { Navigate } from 'react-router-dom';

const UnAuthGuard = ({ component }) => {
  const { auth } = useRecoilValue(authState);

  return !auth ? <>{component}</> : null;
};

export default UnAuthGuard;
