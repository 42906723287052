import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle
  } from "../../ui/dialog.tsx";

import { Button } from "../../ui/button.tsx";
import  axapi  from "../../utils/axios";
import { useState } from "react";
import { TbUserEdit } from "react-icons/tb";
import { FaSave } from "react-icons/fa";
import { FaUser } from "react-icons/fa";

const Features = ({ 
    openFeatureFlag, 
    setOpenFeatureFlag, 
    featuresList, 
    userState, 
    featureStates, 
    setFeatureStates 
}) => {
  const [isEdit, setEdit] = useState(false);

  const handleEdit = () => {
    setFeatureStates(prevStates =>
      Object.keys(prevStates).reduce((acc, feat) => {
        acc[feat] = {
          ...prevStates[feat],
          disabled: false
        };
        return acc;
      }, {})
    );
    setEdit(true);
  };

  const handleSave = async () => {
    
    setFeatureStates(prevStates =>
      Object.keys(prevStates).reduce((acc, feat) => {
        acc[feat] = {
          ...prevStates[feat],
          disabled: true
        };
        return acc;
      }, {})
    );

    setEdit(false);
    const userId = userState.id;
    const featureFlags = Object.keys(featureStates).filter(feat => featureStates[feat].checked);
    try {
      await axapi.post(`admin/${userId}/feature-flags`, { featureFlags });
      console.log('Feature flags saved successfully');
     
    } catch (error) {
      console.error('Error saving feature flags:', error);
    }
  };

  const handleCancel = () => {
    setOpenFeatureFlag(false);
    setEdit(false);

    setFeatureStates(prevStates =>
      Object.keys(prevStates).reduce((acc, feat) => {
        acc[feat] = {
          ...prevStates[feat],
          checked: false,
          disabled: true
        };
        return acc;
      }, {})
    );
  };

  const handleCheckboxChange = (feat) => {
    setFeatureStates(prevStates => ({
      ...prevStates,
      [feat]: {
        ...prevStates[feat],
        checked: !prevStates[feat].checked
      }
    }));
  };

  return (
    <>
      <Dialog open={openFeatureFlag} onOpenChange={handleCancel}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>
            Feature Flags
            </DialogTitle>
            <DialogDescription>
            <div className="absolute right-10 my-2">
               <div className="flex flex-row gap-1">
                <FaUser/>
                {userState.name}
               </div>
              </div>
              <div className="gap-1 flex flex-col mx-2 border-b rounded-lg mt-3 p-3 shadow-md">
                {featuresList.map((feat) => (
                  <label key={feat} className="mb-2 flex items-center">
                    <input
                      type="checkbox"
                      name={feat}
                      checked={featureStates[feat]?.checked}
                      disabled={featureStates[feat]?.disabled}
                      onChange={() => handleCheckboxChange(feat)}
                      className="m-2 h-4 w-4 checked:accent-indigo-900"
                    />
                    {feat}
                  </label>
                ))}
              </div>
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            {!isEdit ? (
               <div className="flex flex-row gap-2">
                 <Button className="bg-slate-300 hover:bg-slate-300 text-black" onClick={handleCancel}>
                  <div className="flex flex-row gap-2">
                    Cancel
                  </div>
                </Button>
                <Button className="hover:bg-blue-500" onClick={handleEdit}>
                <div className="flex flex-row gap-1 justify-center text-center">
                  <div className="pt-1">
                    <TbUserEdit />
                  </div>
                  Edit
                </div>
              </Button>
               </div> 
            ) : (
              <div className="flex flex-row gap-2">
                <Button className="bg-slate-300 hover:bg-slate-300 text-black" onClick={handleCancel}>
                  <div className="flex flex-row gap-2">
                    Cancel
                  </div>
                </Button>
                <Button className="hover:bg-blue-500" onClick={handleSave}>
                  <div className="flex flex-row gap-1 justify-center text-center">
                    <div className="pt-1">
                      <FaSave />
                    </div>
                    Save
                  </div>
                </Button>
              </div>
            )}
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Features;
