import { Route, Routes, useNavigate } from 'react-router-dom';
import AuthRoutes from './routes/auth-routes';
import UnAuthRoutes from './routes/anonymous-routes';
import NotFound from './pages/NotFound';
import { useRecoilState } from 'recoil';
import { authState } from './state/auth';
import { useEffect, useState } from 'react';
import axapi from './utils/axios';
import { CircularProgress } from '@mui/material';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';

export default function RouterModule() {
  const navigator = useNavigate();
  const [, setAuthData] = useRecoilState(authState);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    (async () => {
      const token = localStorage.getItem('token');
     
      if(!token){
          navigator('/auth/login');
          setLoading(false);
      }
       else {
        const res = await axapi.post('/auth/verify-token');
        
        if (res.data.auth) {
          const decoded = jwtDecode(res.data.token);
          setAuthData({
            auth: true,
            user: decoded.id,
            token: res.data.token,
          });
          
          navigator('/');
          setLoading(false);
        } else {
          localStorage.removeItem('token');
          navigator('/auth/login');
          setLoading(false);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '80vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Routes>
          {AuthRoutes}
          {UnAuthRoutes}
          <Route
            path="*"
            element={<NotFound />}
          />
        </Routes>
      )}
    </>
  );
}
