import { useRecoilState } from "recoil";
import { authState } from "../../state/auth";
import { useNavigate } from 'react-router-dom';

const useLogout = () => {
    const [, setAuthData] = useRecoilState(authState);
    const navigate = useNavigate();
    const logout = () => {
        setAuthData({
            auth: false,
            token: "",
            user: "",
        });
      
        localStorage.removeItem('token');
        navigate("/auth/login");
    };

    return logout;
};

export default useLogout;
