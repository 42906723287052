/**
 * HomePage Component
 *
 * @returns {JSX.Element} The rendered content for the HomePage.
 */
export const HomePage = () => {
  return <div>HomePage</div>;
};

export default HomePage;
