import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "../../ui/dialog.tsx";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { ClipLoader } from "react-spinners";
import axapi from "../../utils/axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { FaUser } from "react-icons/fa";

const Credits = ({ openCreditsInfo, setOpenCreditsInfo, userState }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [creditsList, setCreditsList] = useState([]);
  const [editingWF, setEditingWF] = useState(null);
  const [newTotal, setNewTotal] = useState("");

  useEffect(() => {
    const fetchUserCredits = async () => {
      try {
        const response = await axapi.get(`/admin/${userState.id}/credits`);
        const data = Object.entries(response.data.userCredits);
        setCreditsList(data);
      } catch (err) {
        toast.error("Some error occurred, please check console");
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };
    fetchUserCredits();
  }, []);
  const handleCancel = () => {
    setOpenCreditsInfo(false);
  };

  const handleEditClick = (credit) => {
    setEditingWF(credit[0]);
    setNewTotal(credit[1].total);
  };

  const handleTotalChange = (event) => {
    setNewTotal(event.target.value);
  };

  const onTotalChange = async ({ newTotal, workflow }) => {
    try {
      const response = await axapi.put(`/admin/${userState.id}/credits`, {
        workflow,
        newTotal,
      });
      const data = Object.entries(response.data.updatedCredits);
      setCreditsList(data);
    } catch (error) {
      console.error("Error updating credits", error);
    }
  };

  const handleSaveClick = (credit) => {
    onTotalChange({ newTotal, workflow: credit[0] });
    setEditingWF(null);
  };

  return (
    <>
      <Dialog open={openCreditsInfo} onOpenChange={handleCancel}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>Credits Info</DialogTitle>
            <div className="absolute right-14 my-3">
              <div className="flex flex-row gap-1">
                <FaUser />
                {userState.name}
              </div>
            </div>

            <DialogDescription>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow className="text-white font-bold bg-slate-400">
                      <TableCell>Work Flow</TableCell>
                      <TableCell>Total</TableCell>
                      <TableCell>Used</TableCell>
                      <TableCell>Remaining</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isLoading ? (
                      <TableRow>
                        <TableCell colSpan={4} className="p-8 text-center">
                          <div className="flex items-center justify-center">
                            <ClipLoader
                              color="#000066"
                              loading={isLoading}
                              size={25}
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    ) : (
                      creditsList.map((credit, index) => (
                        <TableRow key={index}>
                          <TableCell className="border">{credit[0]}</TableCell>
                          <TableCell className="border">
                            {editingWF === credit[0] ? (
                              <input
                                type="number"
                                value={newTotal}
                                onChange={handleTotalChange}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    handleSaveClick(credit);
                                  }
                                }}
                                className="w-full p-1 border border-gray-300"
                              />
                            ) : (
                              <span onClick={() => handleEditClick(credit)}>
                                {credit[1].total}
                              </span>
                            )}
                          </TableCell>
                          <TableCell className="border">
                            {credit[1].used}
                          </TableCell>
                          <TableCell className="border">
                            {credit[1].total - credit[1].used}
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogDescription>
          </DialogHeader>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Credits;
